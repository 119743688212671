<template>
  <RaiSetups v-model="activeSetupQuery" :loading="loading">
    <PolicySetup
      :type="completedSetups[0]"
      :policy="policy"
      @update:policy="onUpdatePolicy"
      @next="onNext"
    />
    <CustomerRequirementSetup
      :type="completedSetups[1]"
      :requirements="requirements"
      @update:requirements="onUpdateRequirements"
      @next="onNext"
    />
    <EmployeeDRSSetup
      :type="completedSetups[2]"
      :employees="employees"
      :drs-employees="drsEmployees"
      @update:employee="onUpdateEmployee"
      @next="onNext"
    />
    <PrewrittenSMSSetup
      :type="completedSetups[3]"
      :store="store"
      :messages="messages"
      @add-template="onAddMessageTemplate"
      @add-auto-reply="onAddAutoReplyMessage"
      @edit="onEditMessage"
      @next="onNext"
    />
    <PassAndTakeReasonSetup
      :type="completedSetups[4]"
      :take-reasons="takeReasons"
      :pass-reasons="passReasons"
      @update:reasons="onUpdateReasons"
      @next="onNext"
    />
    <GenerateQRCodeSetup
      :type="completedSetups[5]"
      @generate="onGenerateQRCode"
      @next="onNext"
    />
    <YourScannerSetup :type="completedSetups[6]" @next="onNext" />
    <TrainTeamSetup />
  </RaiSetups>
</template>

<script>
import get from "lodash/get";
import { RaiSetups, SetupType } from "@/core-ui";
import { useRouterQuery } from "@/mixins/routerQuery";
import { hasSnackbarAccess } from "@/mixins/ui";
import {
  LEGALESE_QUERY,
  LEGALESE_UPDATE,
  REQUIREMENTS_QUERY,
  EMAIL_REQUIRED_UPDATE,
  LICENSE_REQUIRED_UPDATE,
  EMPLOYEES_QUERY,
  EMPLOYEE_UPDATE,
  DRS_EMPLOYEES_QUERY,
  REASONS_QUERY,
  REASONS_UPDATE,
  GENERATE_QR_CODE,
} from "./graphql";

import {
  STORE_MESSAGES_QUERY,
  STORE_SMS_QUERY,
} from "@/views/Texting/SetupView/graphql";

import PolicySetup from "./components/PolicySetup.vue";
import CustomerRequirementSetup from "./components/CustomerRequirementSetup.vue";
import EmployeeDRSSetup from "./components/EmployeeDRSSetup.vue";
import PassAndTakeReasonSetup from "./components/PassAndTakeReasonSetup.vue";
import PrewrittenSMSSetup from "./components/PrewrittenSMSSetup.vue";
import TrainTeamSetup from "./components/TrainTeamSetup.vue";
import YourScannerSetup from "./components/YourScannerSetup.vue";
import GenerateQRCodeSetup from "./components/GenerateQRCodeSetup.vue";

export default {
  name: "BuySetupView",
  components: {
    RaiSetups,
    PolicySetup,
    CustomerRequirementSetup,
    EmployeeDRSSetup,
    PassAndTakeReasonSetup,
    PrewrittenSMSSetup,
    GenerateQRCodeSetup,
    TrainTeamSetup,
    YourScannerSetup,
  },
  mixins: [
    useRouterQuery({ name: "activeSetup", initialValue: 0 }),
    hasSnackbarAccess,
  ],
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    raiLinkTo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    policy: null,
    requirements: null,
    employees: [],
    drsEmployees: [],
    takeReasons: [],
    passReasons: [],
    messages: [],
    store: {},
    completedSetups: new Array(8),
  }),
  apollo: {
    store: {
      query: STORE_SMS_QUERY,
      variables() {
        return { storeId: this.storeId };
      },
    },
    policy: {
      query: LEGALESE_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update({ store: { buyConfig } }) {
        return buyConfig.legalese;
      },
    },
    requirements: {
      query: REQUIREMENTS_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update({ store: { buyConfig } }) {
        return {
          emailAddress: buyConfig.buysRequireEmail,
          license: buyConfig.buysRequireLicense,
        };
      },
    },
    employees: {
      query: EMPLOYEES_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
    },
    drsEmployees: {
      query: DRS_EMPLOYEES_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
    },
    messages: {
      query: STORE_MESSAGES_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
    },
    takeReasons: {
      query: REASONS_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update({ store: { buyConfig } }) {
        return buyConfig.takeReasons;
      },
    },
    passReasons: {
      query: REASONS_QUERY,
      watchLoading(loading) {
        this.setLoading(loading);
      },
      variables() {
        return { storeId: this.storeId };
      },
      update({ store: { buyConfig } }) {
        return buyConfig.passReasons;
      },
    },
  },
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    onNext() {
      this.completedSetups[this.activeSetupQuery] = SetupType.COMPLETE;
      this.activeSetupQuery++;
    },
    async onUpdatePolicy(value) {
      const { data } = await this.$apollo.mutate({
        mutation: LEGALESE_UPDATE,
        variables: {
          storeId: this.storeId,
          input: value,
        },
      });

      const errors = get(data, "updateLegalese.errors");

      if (errors.length > 0) {
        this.showSnackbar({
          text: this.$t("buy.setup.updatePolicyErrors", [errors.join("; ")]),
        });
      }
    },
    onUpdateRequirements(values) {
      const { emailAddress, license } = values;

      if (emailAddress !== this.requirements.emailAddress) {
        this.updateEmailRequirements(emailAddress);
      }

      if (license !== this.requirements.license) {
        this.updateLicenseRequirements(license);
      }
    },
    async updateEmailRequirements(value) {
      const { data } = await this.$apollo.mutate({
        mutation: EMAIL_REQUIRED_UPDATE,
        variables: {
          storeId: this.storeId,
          input: value,
        },
      });

      const errors = get(data, "updateBuysRequireEmail.errors");

      if (errors.length > 0) {
        this.showSnackbar({
          text: this.$t("buy.setup.updateEmailErrors", [errors.join("; ")]),
        });
      }
    },
    async updateLicenseRequirements(value) {
      const { data } = await this.$apollo.mutate({
        mutation: LICENSE_REQUIRED_UPDATE,
        variables: {
          storeId: this.storeId,
          input: value,
        },
      });

      const errors = get(data, "updateBuysRequireLicense.errors");

      if (errors.length > 0) {
        this.showSnackbar({
          text: this.$t("buy.setup.updateLIcenseErrors", [errors.join("; ")]),
        });
      }
    },
    async onUpdateEmployee(value) {
      const { id, drsEmployeeCode } = value.employee;
      const { data } = await this.$apollo.mutate({
        mutation: EMPLOYEE_UPDATE,
        variables: {
          input: {
            id: id,
            drsEmployeeCode: drsEmployeeCode,
          },
        },
      });

      const errors = get(data, "employeeUpdate.errors");

      if (errors.length > 0) {
        this.showSnackbar({
          text: this.$t("buy.setup.updateEmployeeErrors", [errors.join("; ")]),
        });
      }
    },
    async onUpdateReasons(reasons) {
      const { data } = await this.$apollo.mutate({
        mutation: REASONS_UPDATE,
        variables: {
          storeId: this.storeId,
          input: reasons,
        },
      });

      const errors = get(data, "buyConfigReasonsUpdate.errors");

      if (errors.length > 0) {
        this.showSnackbar({
          text: this.$t("buy.setup.updateResonsErrors", [errors.join("; ")]),
        });
      }
    },
    async onGenerateQRCode({ values, resolve, setErrors }) {
      const { data } = await this.$apollo.mutate({
        mutation: GENERATE_QR_CODE,
        variables: {
          input: values,
        },
      });

      const errors = get(data, "generateQrCode.errors");

      if (errors?.length > 0) {
        setErrors(errors);
        return;
      }

      this.showSnackbar({
        text: this.$t("buy.setup.generateQRCode.successMessage"),
      });

      resolve();
    },
    onAddMessageTemplate() {
      this.$router.push({
        name: this.raiLinkTo.addMessageTemplate,
        preserveQuery: true,
      });
    },
    onAddAutoReplyMessage() {
      this.$router.push({
        name: this.raiLinkTo.addAutoReply,
        preserveQuery: true,
      });
    },
    onEditMessage(item) {
      this.$router.push({
        name: item.isAutoReply
          ? this.raiLinkTo.editAutoReply
          : this.raiLinkTo.editMessageTemplate,
        params: { messageId: parseInt(item.id) },
        preserveQuery: true,
      });
    },
  },
};
</script>
